<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">智能座舱</h1>
				<p data-caption-delay="100" class="wow fadeInRight">提供智能座舱域从HMI前期开发，到系统及部件的功能/性能测试，以及实车测试的全流程自动化解决方案</p>
				<div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
			<div class="container">
				<el-tabs v-model="activeProduct" @tab-click="handleTabClick">
					<el-tab-pane label="智能座舱自动化测试解决方案总述" name="1">智能座舱自动化测试解决方案总述</el-tab-pane>
					<el-tab-pane label="HMI前期设计验证" name="2">HMI前期设计验证</el-tab-pane>
					<el-tab-pane label="HMI自动化测试" name="3">HMI自动化测试</el-tab-pane>
					<el-tab-pane label="OTA测试" name="4">OTA测试</el-tab-pane>
					<el-tab-pane label="AR-HUD 测试" name="5">AR-HUD 测试</el-tab-pane>
					<el-tab-pane label="实车自动化测试" name="6">实车自动化测试</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div :class="tabFixed?'solution-tab-fixed':''">
			<div v-if="activeProduct === '1'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">业务背景</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<div class="intro-text" style="width: 100%;">
										<p>在国内汽车市场日新月异的发展浪潮中，智能座舱愈发成为消费者选购汽车产品时的决定性考量之一，各家整车厂及供应商也在逐年加大该领域研发投入。
										</p>
										<p>
											智能座舱领域涉及人因工程、多屏显示、语音交互、触控交互、UI/UE开发、软件测试等多个不同于传统汽车行业的新兴领域，因此原有的测试设备和验证系统无法沿用到智能座舱测试领域。面对这样的行业痛点，东信创智基于自身在传统测试领域的雄厚实力，积极开拓新领域能力，经过多年的耕耘，与客户携手打造了智能座舱自动化测试解决方案。
										</p>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections cockpitHIL-solution-function wow fadeInRight">
					<div class="container">
						<div class="normal-title">业务覆盖</div>
						<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
							<div class="right-picture-1">
								<img src="../../../assets/images/solution-cockpit01-function-pic02.png" alt="">
							</div>
						</el-col>
						<el-row>
							<el-col>
								<div class="function-intro">
									<p>东信创智智能座舱自动化测试解决方案覆盖了从前期需求分析、交互逻辑设计、UI/UE设计验证、人机工程设计到后期零部件至整车逐级验证测试的全开发周期，为软件开发团队、零部件/系统开发团队、UI/UE设计团队、人机工程开发团队、交互式体验设计团队和功能测试团队均可提供大幅提升工作效率的自动化测试解决方案。
										针对智能座舱领域的测试核心——感知与交互测试，东信创智智能座舱自动化测试解决方案可覆盖三大测试方向：</p>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
								<div class="all-function">
									<ul class="priority-list">
										<li>功能测试</li>
										<li>性能测试</li>
										<li>主观体验测试</li>
									</ul>
								</div>
							</el-col>
							<el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
								<div class="right-picture-1">
									<img src="../../../assets/images/solution-cockpit01-function-pic.png" alt=""
										style="width: 70%;">
								</div>
							</el-col>
						</el-row>
					</div>
				</section>
			</div>
			<div v-if="activeProduct === '2'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<div class="intro-text" style="width: 100%;">
										<p>智能座舱系统集成HMI测试验证系统按功能特点可分为柔性座舱系统、HMI验证系统、驾驶模拟系统、生理监测系统等多个子系统，可覆盖HMI关键人机参数验证、静态/动态交互逻辑及UI/UX验证、驾驶模拟等测试项。用定制化的柔性座舱台架完成新车型开发前期的HMI人机关键参数效果验证及前沿的人机交互方式探索，高效助力新车型智能座舱的设计开发。
										</p>

									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections cockpitHIL-solution-function wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>

						<el-row>
							<el-col>
								<div class="function-intro">
									<p>根据具体需求提供定制化的解决方案，形成一套功能覆盖面全、可实现多维度座舱HMI验证的交钥匙工程。各个子系统功能特点简单概括如下：</p>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
								<div class="all-function">
									<ul class="priority-list">
										<li>可高效助力新车型开发前期的HMI效果验证</li>
										<li>可实现静态/动态交互逻辑及UI/UX验证</li>
										<li>可实现多种生理监测数据的同步采集分析</li>
									</ul>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
								<el-table style="font-size: 14px" :data="functionTableData"
									:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border size="small">
									<el-table-column prop="project" label="子系统名称" width="240" />
									<el-table-column prop="description" label="功能特点" />
								</el-table>
							</el-col>
						</el-row>
					</div>
				</section>
				<section class="ethernet-solution-components wow fadeInLeft">
					<div class="container" style="height: 410px;">
						<div class="normal-title">核心组件</div>
						<div class="solution-components">
							<div class="components-content">
								<el-row :gutter="10">
									<el-col :span="24">
										<div class="components-table">
											<el-table style="font-size: 14px;" :data="componentsTableData"
												:span-method="componentsArraySpanMethod"
												:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border
												size="small">
												<el-table-column prop="type" label="分类" width="100" />
												<el-table-column prop="project" label="描述" />
											</el-table>
										</div>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</section>
				<section class="network-solution-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<div class="all-system-picture">
							<div class="system-picture">
								<img src="../../../assets/images/solution-cokpit02-system-pic.png" alt="">
							</div>

						</div>
					</div>
				</section>
			</div>
			<div v-if="activeProduct === '3'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<div class="intro-text" style="width: 100%;">
										<p>智能座舱功能性能测试系统可覆盖仪表显示、多屏交互、语音交互、DMS疲劳监测、人脸识别、触控交互、手势识别、总线交互等全功能。用定制化的方案及设备代替人工操作及识别判定，完成信息娱乐域功能及性能的自动化测试，高效助力智能座舱软件系统的快速迭代。
										</p>
										<p>根据具体需求提供定制化的解决方案，形成一套功能覆盖面全、可实现全流程自动化测试的交钥匙工程。可覆盖测试项包含但不限于以下部分：
										</p>
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
									<el-table style="font-size: 14px" :data="functionTableData3"
										:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border size="small">
										<el-table-column prop="project" label="项目类型" width="240" />
										<el-table-column prop="description" label="测试项" />
									</el-table>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections cockpitHIL-solution-function wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
								<div class="all-function">
									<ul class="priority-list">
										<li>可实现行业痛点卡黑花屏专项测试</li>
										<li>自动化测试软件图形化界面操作便捷</li>
										<li>基于Vector工具成熟的ETH模拟仿真</li>
									</ul>
								</div>
							</el-col>
						</el-row>

					</div>
				</section>
				<section class="ethernet-solution-components wow fadeInLeft">
					<div class="container" style="height: 410px;">
						<div class="normal-title">核心组件</div>
						<div class="solution-components">
							<div class="components-content">
								<el-row :gutter="10">
									<el-col :span="24">
										<div class="components-table">
											<el-table style="font-size: 14px;" :data="componentsTableData3"
												:span-method="componentsArraySpanMethod"
												:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border
												size="small">
												<el-table-column prop="type" label="分类" width="100" />
												<el-table-column prop="project" label="描述" />
											</el-table>
										</div>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</section>
				<section class="network-solution-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<div class="all-system-picture">
							<div class="system-picture white-bg-pic-deal">
								<img src="../../../assets/images/solution-cokpit03-system-pic.png" style="width: 60% !important;" alt="">
							</div>

						</div>
					</div>
				</section>
			</div>
			<div v-if="activeProduct === '4'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<div class="intro-text" style="width: 100%;">
										<p>汽车智能化时代，
											OTA（Over-the-Air）升级已经成为了一种趋势。通过OTA升级，汽车制造商可以远程更新车辆的软件，改善汽车性能、修复已知漏洞，确保OTA升级的稳定性和安全性成为一个关键的挑战。相较于传统实车测试不易在信号层面控制，且迭代慢、升级时间长等问题，东信创智推出全流程OTA
											HIL仿真自动化测试，更充分的验证整个OTA机制，不仅极大的缩短了时间和人力成本，还可以实现实车较难仿真的特殊工况，并且具备远程控制功能，让测试人员足不出户完成测试。
										</p>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<div class="all-function">
							<ul class="priority-list" style="margin-top: 20px">
								<li>OTA升级全流程自动化，实现早期测试，自动生成测试结果，提高测试效率，降低人力和时间成本</li>
								<li>减低误差，由于自动化测试识别不会疲劳和犯错，提高测试准确性</li>
								<li>信号级测试仿真使得问题定位更精准，更易复现</li>
								<li>覆盖OTA场景更完整，可以仿真各种实车难以复现的特殊工况 </li>
								<li>OTA自动化测试系统软硬件预留接口，后续设备增加可在测试管理平台扩展，硬件预留空间易扩展</li>
								<li>接入多个控制器，可单独控制每个被测件的供电和刷写</li>
								<li>远程自动化测试，测试数据统一线上管理，足不出户结果查看</li>
								<li>远程自动化测试管理平台可依据客户需求定制</li>
							</ul>
						</div>

						<div class="right-picture-1">
							<img src="../../../assets/images/solution-cockpit04-function-pic.png" alt=""
								style="width:80%">
						</div>
						<br />
						<el-row :gutter="10">
							<el-col :span="24">
								<div class="components-table">
									<el-table style="font-size: 14px;" :data="componentsTableData4"
										:span-method="componentsArraySpanMethod"
										:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border size="small">
										<el-table-column prop="type" label="测试内容" width="150" />
										<el-table-column prop="project" label="内容介绍" />
									</el-table>
								</div>
							</el-col>
						</el-row>
					</div>
				</section>
				<section class="electric-solution-components network-solution-components wow fadeInLeft">
					<div class="container">
						<div class="normal-title">核心组件</div>
						<div class="solution-components">
							<el-row class="components-content" :gutter="40">
								<el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
									<img src="../../../assets/images/solution-hil-components.png" alt="">
								</el-col>
								<el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
									<div class="components-text">
										<div>
											<h1>实时平台</h1>
											<ol>
												<li>Vector VT System</li>
											</ol>
										</div>
										<div style="margin-top: 50px">
											<h1>试验管理软件</h1>
											<ol>
												<li>CANoe</li>
											</ol>
										</div>
										<div style="margin-top: 30px">
											<h1>自动化测试软件</h1>
											<ol>
												<li>vTESTstudio、测试编程脚本或其他同类型软件</li>
											</ol>
										</div>
										<div style="margin-top: 50px">
											<h1>测试管理平台</h1>
											<ol>
												<li>东信创智自研远程自动化测试管理平台</li>
											</ol>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>

				<section class="network-solution-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<div class="all-system-picture">
							<div class="system-picture">
								<img src="../../../assets/images/solution-ota-system-pic.png" alt="">
							</div>
						</div>
					</div>
				</section>
			</div>
			<div v-if="activeProduct === '5'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<div class="intro-text" style="width: 100%;">
										<p>AR-HUD功能性能测试方案可选两种测试系统，即信号级仿真测试和系统级仿真测试，覆盖AR-HUD显示内容、多屏信息一致性、显示质量、虚拟助手、交通道路标志识别、AR-HUD动态响应、车控及总线等全功能仿真测试需求。采用定制化的硬件平台方案，通过图像模板匹配、AI算法等，快速完成AR-HUD显示内容的分析和显示质量的检测，实现全自动化功能及性能的测试。
										</p>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections solution-functions wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<el-row>
							<el-col>
								<div class="function-intro">
									<p>提供私有定制化部署的解决方案，形成一套功能覆盖面全、可实现全流程自动化测试的交钥匙工程。可覆盖测试项包含但不限于以下部分：</p>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
								<div class="all-function">
									<ul class="priority-list">
										<li>可实现AR-HUD动态响应时间，冷、热启动响应时间等专项测试</li>
										<li>自动化测试软件功能集成度高，视频注入、设备控制、图像识别、语音等均可在一个平台统一调度管理和图形化显示，测试脚本开发效率高</li>
										<li>集成Vector工具链，提供成熟稳定的总线仿真能力</li>
									</ul>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
								<el-table style="font-size: 14px" :data="functionTableData5"
									:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border size="small">
									<el-table-column prop="project" label="项目类型" width="240" />
									<el-table-column prop="description" label="测试项" />
								</el-table>
							</el-col>
						</el-row>
					</div>
				</section>
				<section
					class="electric-solution-components network-solution-components hudxil-components wow fadeInLeft">
					<div class="container" style="height: 410px;">
						<div class="normal-title">核心组件</div>
						<div class="solution-components">
							<div class="components-content">
								<el-row :gutter="10">
									<el-col :span="24">
										<div class="components-table">
											<el-table style="font-size: 14px;" :data="componentsTableData5"
												:span-method="componentsArraySpanMethod"
												:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border
												size="small">
												<el-table-column prop="type" label="分类" width="140" />
												<el-table-column prop="project" label="描述" />
											</el-table>
										</div>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</section>
				<section class="network-solution-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<div class="all-system-picture">
							<el-row :gutter="20">
								<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
									<img class="xil-sys-img"
										src="../../../assets/images/solution-hudxil-system-pic-1.png" alt=""
										style="width: 100%; ">
								</el-col>
								<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
									<img src="../../../assets/images/solution-hudxil-system-pic-2.png" alt=""
										style="width: 100%">

								</el-col>
							</el-row>
						</div>
					</div>
				</section>
			</div>
			<div v-if="activeProduct === '6'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<div class="intro-text" style="width: 100%;">
										<p>实车自动化测试系统包含车载机械手、车载分布式语音交互系统、仿真头模、踏板机器人、挡位机器人、组合开关机器人、车门钥匙交互AGV小车、车载总线/信号采集系统等设备，该系统设备可适配所有车型并在极短时间内完成车型更换，专为测试车型庞杂、测试任务繁重、测试人员紧缺、测试需求急迫的测试业务部门打造。
										</p>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections solution-functions wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<el-row>
							<el-col>
								<div class="function-intro">
									<p>根据具体需求提供定制化的解决方案，形成一套功能覆盖面全、可实现全流程自动化测试的交钥匙工程。可覆盖测试项包含但不限于以下部分：</p>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
								<div class="all-function">
									<ul class="priority-list">
										<li>快速部署应用，高效执行重复度高且任务量重的实车测试任务</li>
										<li>通用性强，单套设备即可覆盖全部车型，适配灵活</li>
										<li>测试脚本在不同项目间复用率极高，维护成本低</li>
									</ul>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
								<el-table style="font-size: 14px" :data="functionTableData6"
									:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border size="small">
									<el-table-column prop="project" label="项目类型" width="240" />
									<el-table-column prop="description" label="测试项" />
								</el-table>
							</el-col>
						</el-row>
					</div>
				</section>
				<section
					class="electric-solution-components network-solution-components hudxil-components wow fadeInLeft">
					<div class="container" style="height: 410px;">
						<div class="normal-title">核心组件</div>
						<div class="solution-components">
							<div class="components-content">
								<el-row :gutter="10">
									<el-col :span="24">
										<div class="components-table">
											<el-table style="font-size: 14px;" :data="componentsTableData6"
												:span-method="componentsArraySpanMethod"
												:header-cell-style="{background:'#296CA8',color:'#FFFFFF'}" border
												size="small">
												<el-table-column prop="type" label="分类" width="100" />
												<el-table-column prop="project" label="描述" />
											</el-table>
										</div>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</section>

				<section class="network-solution-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<div class="all-system-picture">
							<div class="system-picture">
								<img src="../../../assets/images/solution-cokpit6-system-pic.png" alt="">
							</div>

						</div>
					</div>
				</section>
			</div>
		</div>
		<bzInfo style="margin-top: 0" />
		<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from '@/components/MainNav'
	import mainFooter from '@/components/MainFooter'
	import bzInfo from '@/components/BzInfo'
	export default {
		name: "Cockpit",
		components: {
			mainNav,
			mainFooter,
			bzInfo
		},
		data() {
			return {
				activeIndex: '2-4',
				tabFixed: false,
				activeProduct: '1',
				functionTableData: [{
						project: "柔性座舱系统",
						description: "驾驶舱尺寸柔性可调、可实现不同车型布置方案的复现；舱体内饰位置柔性可调、可实现不同内饰组合方案的效果验证",
					},
					{
						project: "HMI验证系统",
						description: "可与座舱基础硬件打通，实现前期静态/ 动态交互逻辑及UI/UX验证",
					},
					{
						project: "驾驶模拟系统",
						description: "搭配六自由度平台及3D仿真场景，可真实还原路感，提供高度接近实车的驾驶体验，并可实时采集分析实车数据及交通环境数据",
					},
					{
						project: "生理监测系统",
						description: "可涵盖眼动分析、行为观测，可同步采集心电、皮电、肌电、脑电、脉搏等数据并接入多模态数据协同管理平台，实现人-机-环境数据的同步分析",
					},

				],
				functionTableData3: [{
						project: "座舱功能测试",
						description: "语音交互、触控交互、多屏交互、HUD、导航、DMS、OMS、AVM全景等",
					},
					{
						project: "生物特征识别",
						description: "人脸识别、掌纹识别、指纹识别等",
					},
					{
						project: "总线及其他",
						description: "CAN、LIN、ETH、A2B、BT、WLAN、NFC、USB、SD卡等",
					},
					{
						project: "座舱性能测试",
						description: "车机压力测试、车机灵敏度测试、反季节稳定性测试等",
					},
				],
				functionTableData5: [{
						project: "AR-HUD显示",
						description: "显示内容准确性测试、亮度及均匀性测试、虚像距离与视场角测试、图像畸变与清晰度测试、位置准确度测试、环境适应性测试",
					},
					{
						project: "多屏信息一致性",
						description: "导航、车控、警告等信息的一致性，信息响应延迟（如车速）",
					},
					{
						project: "稳定性与可靠性测试",
						description: "测试不同工况条件下的车机性能，以各个任务执行的最长不崩溃（包括卡顿、自动关机、局部信息显示不全等异常）运行时间为评价指标；冷、热启动测试",
					},
					{
						project: "信息同步性测试",
						description: "导航信息显示（中控与AR-HUD之间）的信息同步及延迟，AR-HUD动态引导与路况的同步性",
					},
					{
						project: "交通道路标识显示",
						description: "响应时间、准确率等",
					},
				],

				functionTableData6: [{
						project: "座舱功能测试",
						description: "多区域语音交互、触控交互、多屏交互、HUD、导航、DMS、OMS、AVM全景等",
					},
					{
						project: "总线仿真及采集",
						description: "CAN、LIN、ETH、A2B、BT、WLAN、NFC、USB、SD卡等",
					},
					{
						project: "使用场景模拟测试",
						description: "无钥匙进入、UWB远程控车、模拟踏板操作/挡位操作/组合开关操作等",
					},

				],
				componentsTableData: [{
						type: "硬件",
						project: "含柔性座舱台架、可调节式前风挡、舱内屏幕组合、线控方向盘、六自由度平台、眼动分析仪等",
					},
					{
						type: "软件",
						project: "含场景仿真软件VTD、试验管理软件、交互原型设计软件、多模态数据协同管理平台等。",
					}],
					componentsTableData5: [{
							type: "硬件（信号级）",
							project: "含AR-HUD测试台架、视频注入设备、视频采集设备、语音交互设备（或音频仿真设备）、Vector总线仿真设备、Vector模拟I/O设备、程控电源等。",
						}, {
							type: "硬件（系统级）",
							project: "含AR-HUD测试台架、视觉图像识别设备、语音交互设备、Vector总线仿真设备、Vector模拟I/O设备、程控电源等。",
						},
						{
							type: "软件",
							project: "含自动化测试软件、试验管理软件CANoe、场景仿真软件VTD等。",
						}
					], componentsTableData4: [{
							type: "功能测试",
							project: "开展端到端的符合性测试、模拟升级等。",
						},
						{
							type: "压力测试",
							project: "单点和多点循环测试（耐久）兼容性和稳定性、备份容灾等。",
						},
						{
							type: "信息安全测试",
							project: "升级包签名标志位的篡改、升级包真实性、完整性校验、车辆身份认证等。",
						},
						{
							type: "性能测试",
							project: "升级效率、系统稳定性、响应时间等测试。",
						},
						{
							type: "健壮性测试",
							project: "验证OTA系统稳定性以及异常处理情况，包括网络延迟、丢包、中断、总线异常等。",
						},
						{
							type: "升级协议测试",
							project: "车云交互协议、诊断协议、CAN/CANFD、车载以太网通讯等。",
						},
						{
							type: "故障注入测试",
							project: "控制器针脚的故障注入测试，模拟系统中的故障情况，以评估系统的稳定性和鲁棒性。",
						},
						{
							type: "云端测试",
							project: "控制器针脚的故障注入测试，模拟系统中的故障情况，以评估系统的稳定性和鲁棒性。",
						},
						{
							type: "管端测试",
							project: "传输协议测试、传输异常测试、异常处理测试等。",
						},
						{
							type: "部件节点测试",
							project: "Master校验测试、网关数据路由测试、Slave版本刷写测试、Client人机交互测试等。",
						},
						{
							type: "APP远程升级测试",
							project: "APP预约升级测试、升级进度查询、结果反馈测试等。",
						},
						{
							type: "快速点检测试",
							project: "APP预约升级测试、升级进度查询、结果反馈测试等",
						},
						{
							type: "快速点检测试",
							project: "版本迭代时，正式升级包下发之前的快速点检。",
						}
					],

					componentsTableData3: [{
							type: "硬件",
							project: "含机械手台架、座舱点击器、视觉图像识别设备、语音交互设备、Vector总线仿真设备、程控电源等。",
						},
						{
							type: "软件",
							project: "含自动化测试软件、试验管理软件CANoe、云端测试管理平台等。",
						}
					],
					componentsTableData6: [{
							type: "硬件",
							project: "车载机械手、车载分布式语音交互系统、仿真头模、踏板机器人、换挡机器人、组合开关机器人、车门钥匙交互AGV小车、车载Vector总线/信号仿真设备等。",
						},
						{
							type: "软件",
							project: "含自动化测试软件、试验管理软件CANoe、云端测试管理平台等。",
						}
					],
					chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
					isMobile: false
				}
			},
			mounted() {
					new this.$wow.WOW().init();
					document.title = "智能座舱 - 解决方案 - 东信创智";
					window.addEventListener("scroll", this.handleScroll);
					this.isMobile = document.documentElement.clientWidth < 1200
					window.onresize = () => {
						return (() => {
							this.isMobile = document.documentElement.clientWidth < 1200;
						})();
					}
				},
				destroyed() {
					window.removeEventListener("scroll", this.handleScroll);
				},
				methods: {
					linkUrl(url) {
						window.open(url, '_blank') // 在新窗口打开外链接
						// window.location.href =this.indexro;  //在本页面打开外部链接
					},
					handleScroll() {
						if (document.documentElement.clientWidth > 1100) {
							let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
							if (top < 0 && top > -630) {
								this.tabFixed = !this.tabFixed;
							} else if (document.documentElement.scrollTop > 630) {
								this.tabFixed = true;
							}
						} else {
							let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
							if (top < 0 && top > -250) {
								this.tabFixed = !this.tabFixed;
							} else if (document.documentElement.scrollTop > 250) {
								this.tabFixed = true;
							}
						}
					},
					getTabFixedClass() {
						if (document.documentElement.clientWidth < 1100) {
							return 'sw-tab-title-mobile-fixed'
						} else {
							return 'sw-tab-title-fixed'
						}
					},
					handleTabClick() {
						if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
							document.documentElement.scrollTop = 630
						} else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop >
							250) {
							document.documentElement.scrollTop = 250
						}
					}
				}
		}
</script>

<style scoped>

</style>
